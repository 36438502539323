class AuctionClientType {
  static FACEBOOK_BIDDING_KIT = 'FACEBOOK_BIDDING_KIT';
  static IN_HOUSE = 'IN_HOUSE';

  static getAll() {
    return [AuctionClientType.FACEBOOK_BIDDING_KIT, AuctionClientType.IN_HOUSE];
  }

  static displayProps = {
    FACEBOOK_BIDDING_KIT: {
      name: 'Facebook BKS',
    },
    IN_HOUSE: {
      name: 'In-House',
    },
  };

  static getName(type) {
    return AuctionClientType.displayProps[type].name;
  }
}
export default AuctionClientType;
