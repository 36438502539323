
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@material-ui/core/MenuItem';

import AdNetworkType from "./AdNetworkType";

class AdNetworkSelector extends Component {
  constructor(props, context) {
    super(props, context);
    let selected = props.selected || '';

    this.state = {
      selected: selected,
    };
  }

  notifySelectionChanged(selected) {
    this.props.onChange(selected);
  }

  handleItemClick = (selected) => {
    this.setState({ ...this.state, selected: selected });
    this.notifySelectionChanged(selected);
  };

  render() {
    let selected = this.state.selected;

    const handleItemClick = this.handleItemClick;
    let options = AdNetworkType.getAll();

    return (
      <div>
        {options.map(function (option) {
          return (
            <MenuItem
              key={option}
              button
              onClick={() => handleItemClick(option)}
              selected={selected === option}
            >
              {option}
            </MenuItem>
          );
        })}
      </div>
    );
  }
}

AdNetworkSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string,
};

export default AdNetworkSelector;
