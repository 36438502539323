import React, { useCallback, useEffect } from 'react';
import DataManager from '../store/DataManager';
import Store from '../store/Store';
import { Link } from 'react-router-dom';

let contentStyle = {
  position: 'absolute',
  top: '50%',
  left: '25%',
  width: '50%',
  textAlign: 'center',
};

let textStyle = {
  color: '#888',
};

const Auth = (props) => {
  useEffect(() => {
    document.title = 'Octopus - Login';
  }, []);

  const startLoginWithActiveDirectory = useCallback(() => {
    let urlState = window.location.pathname + window.location.search;
    if (urlState.includes('auth')) {
      urlState = '/';
    }
    let stateEncoded = encodeURIComponent(urlState);
    DataManager.server.sendJson(
      `auth?getSignInUrl&origin=${window.location.origin}&state=${stateEncoded}`,
      null,
      null,
      (result) => {
        if (result.success) {
          let signInUrl = result.data.signInUrl;
          window.location.replace(signInUrl);
        } else {
          Store.configureFailed(result.error);
        }
      }
    );
  }, []);

  const finishLogin = useCallback((code, state) => {
    DataManager.server.sendJson(
      'auth',
      'data',
      { code: code, origin: window.location.origin },
      (result) => {
        if (result.success && result.data.outcome === 'LOGGED_IN') {
          window.location.replace(state);
        } else {
          console.log(result.error);
          Store.configureFailed(result.error);
        }
      }
    );
  }, []);

  let code = null;
  let state = null;
  let signedout = window.location.href.includes('auth?signedout') === true;

  if (props.location) {
    let urlParams = new URLSearchParams(props.location.hash.substr(1));
    code = urlParams.get('code');
    state = urlParams.get('state');
  }

  let isLoginEnd = code !== null && state !== null;
  let isLoginStart = !isLoginEnd && !signedout;

  useEffect(() => {
    if (DataManager.server) {
      if (isLoginStart) {
        startLoginWithActiveDirectory();
      } else if (isLoginEnd) {
        finishLogin(code, state);
      }
    }
  }, [
    code,
    state,
    signedout,
    isLoginStart,
    isLoginEnd,
    startLoginWithActiveDirectory,
    finishLogin,
  ]);

  if (signedout) {
    return (
      <div style={contentStyle}>
        <p style={textStyle}>Octopus session ended.</p>
        <Link to={'/auth'}>Sign in again</Link>
      </div>
    );
  }

  return (
    <div style={contentStyle}>
      <img
        className="progress"
        src="/img/octopus4_progress.png"
        alt=""
        width="64"
        height="64"
      />
      <p style={textStyle}>Login with Playtika Active Directory</p>
    </div>
  );
};

export default Auth;
