import React, { useMemo, useState, useCallback } from 'react';

import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ListSubheader, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  list: {
    overflow: 'auto',
    maxHeight: '600px',
  },

  sectionList: {
    padding: '0px',
    backgroundColor: 'white',
  },

  selectSectionButton: {
    fontSize: '14px',
    color: palette.primary.main,
  },
}));

const getInitialEditedOptions = (options, selectedOptions) => {
  const editedSelectedOptions = {};

  if (selectedOptions) {
    selectedOptions.forEach((option) => (editedSelectedOptions[option] = true));
  } else {
    options.forEach((option) => (editedSelectedOptions[option] = true));
  }

  return editedSelectedOptions;
};

const Select = ({
  onChange,
  onFormatLabel,
  selectedOptions,
  options: originalOptions,
}) => {
  const classes = useStyles();

  const options = useMemo(() => {
    if (Array.isArray(originalOptions)) {
      return originalOptions;
    }

    let mergedOptions = [];

    for (let key in originalOptions) {
      if (originalOptions.hasOwnProperty(key)) {
        mergedOptions = [...mergedOptions, ...originalOptions[key]];
      }
    }

    return mergedOptions;
  }, [originalOptions]);

  const [editedSelectedOptions, setEditedSelectedOptions] = useState(
    getInitialEditedOptions(options, selectedOptions)
  );

  const disableSelectAll = useMemo(
    () => !selectedOptions || selectedOptions.length === options.length,
    [options, selectedOptions]
  );

  const handleNotifySelectionChanged = useCallback(
    (selectedMap) => {
      let selection = [];

      for (let selectedItem in selectedMap) {
        if (
          selectedMap.hasOwnProperty(selectedItem) &&
          selectedMap[selectedItem]
        ) {
          selection.push(selectedItem);
        }
      }

      if (selection.length === options.length) {
        selection = null;
      }

      onChange(selection);
    },
    [options, onChange]
  );

  const handleSelectAll = useCallback(() => {
    const selected = {};
    options.forEach((option) => (selected[option] = true));

    setEditedSelectedOptions(selected);
    handleNotifySelectionChanged(selected);
  }, [options, handleNotifySelectionChanged]);

  const handleSelectAllSection = useCallback(
    (sectionKey) => () => {
      const selected = {};
      const sectionOptions = originalOptions[sectionKey];
      sectionOptions.forEach((option) => (selected[option] = true));

      setEditedSelectedOptions(selected);
      handleNotifySelectionChanged(selected);
    },
    [originalOptions, handleNotifySelectionChanged]
  );

  const handleOnChange = useCallback(
    (option) => () => {
      let selected;
      const countSelected = (selectedOptions || options).length;
      const areAllSelected = countSelected === options.length;

      if (areAllSelected) {
        // Select first
        selected = {};
        selected[option] = true;

        setEditedSelectedOptions(selected);
        handleNotifySelectionChanged(selected);
      } else {
        selected = { ...editedSelectedOptions };
        if (selected[option] && countSelected === 1) {
          // deselect last - select all
          handleSelectAll();
        } else {
          selected[option] = !selected[option];
          setEditedSelectedOptions(selected);

          handleNotifySelectionChanged(selected);
        }
      }
    },
    [
      options,
      selectedOptions,
      editedSelectedOptions,
      handleSelectAll,
      handleNotifySelectionChanged,
    ]
  );

  const renderCheckbox = (isSelected) => {
    const content =
      !disableSelectAll && isSelected ? 'check_box' : 'check_box_outline_blank';
    return <Icon color="action">{content}</Icon>;
  };

  const renderLabel = (option) => {
    let label = onFormatLabel ? onFormatLabel(option) : option;
    return <ListItemText primary={label} />;
  };

  const renderListItems = (options) =>
    options.map((option) => (
      <ListItem button key={option} onClick={handleOnChange(option)}>
        {renderCheckbox(editedSelectedOptions[option])}
        {renderLabel(option)}
      </ListItem>
    ));

  const renderSelectAllSectionItem = (sectionKey) => (
    <ListItem
      button
      className={classes.selectSectionButton}
      onClick={handleSelectAllSection(sectionKey)}
    >
      Select only {sectionKey}
    </ListItem>
  );

  const renderListContent = () => {
    if (Array.isArray(originalOptions)) {
      return renderListItems(originalOptions);
    }

    const sections = [];

    for (let key in originalOptions) {
      if (originalOptions.hasOwnProperty(key)) {
        sections.push(
          <li key={key}>
            <ul className={classes.sectionList}>
              <ListSubheader>{key}</ListSubheader>
              {renderSelectAllSectionItem(key)}
              {renderListItems(originalOptions[key])}
            </ul>
          </li>
        );
      }
    }

    return sections;
  };

  return (
    <div>
      <Button
        color="primary"
        onClick={handleSelectAll}
        disabled={disableSelectAll}
      >
        Select all
      </Button>

      <div className={classes.list}>
        <List dense subheader={<li />}>
          {renderListContent()}
        </List>
      </div>
    </div>
  );
};

Select.propTypes = {
  selectedOptions: PropTypes.array,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,

  onFormatLabel: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

export default Select;
