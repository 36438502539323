import DateRange from '../DateRange';
import AppActionScope from '../AppActionScope';
import EditedAppHelper from '../EditedAppHelper';

class AppActivityHelper {
  static getHasSelectedMediation(selectedScopes) {
    return !selectedScopes || selectedScopes.includes(AppActionScope.MEDIATION);
  }

  static applyRuleFilter = ({ activity, selectedMediationRules }) => {
    const allMediationRules = EditedAppHelper.getAllMediationRules();

    if (
      !selectedMediationRules ||
      selectedMediationRules.length === allMediationRules.length
    ) {
      return activity;
    }

    return activity.filter((entry) => {
      let ruleId = entry.key4;

      // Detect the "default" rule for an ad type
      if (ruleId === '0') {
        ruleId = `${entry.key3}_${ruleId}`;
      }

      return !(
        entry.key2 === AppActionScope.MEDIATION &&
        !selectedMediationRules.includes(ruleId)
      );
    });
  };

  static applyScopeFilter = ({ activity, selectedScopes }) => {
    if (
      !selectedScopes ||
      selectedScopes.length === AppActionScope.getAll().length
    ) {
      return activity;
    }

    return activity.filter((entry) => selectedScopes.indexOf(entry.key2) >= 0);
  };

  static applyFilters = ({
    activity,
    selectedScopes,
    selectedUserId,
    selectedDateRange,
    selectedMediationRules,
  }) => {
    let selectedRange = DateRange.getDates(selectedDateRange);
    let selectedUsers = selectedUserId;

    let fromTime = selectedRange.from.getTime();
    let toTime = selectedRange.to.getTime() + 1000 * 60 * 60 * 24; // End of day

    const hasSelectedMediation =
      AppActivityHelper.getHasSelectedMediation(selectedScopes);

    let filteredActivity = AppActivityHelper.applyScopeFilter({
      activity,
      selectedScopes,
    })
      .filter((act) => act.timestamp >= fromTime && act.timestamp < toTime)
      .filter((act) =>
        selectedUsers ? selectedUsers.includes(act.userId) : true
      );

    if (hasSelectedMediation) {
      filteredActivity = AppActivityHelper.applyRuleFilter({
        selectedMediationRules,
        activity: filteredActivity,
      });
    }

    return filteredActivity;
  };

  static getChangingUserIdsByScope(selectedScopes) {
    const activity = EditedAppHelper.getActivityLog();

    return [
      ...new Set(
        AppActivityHelper.applyScopeFilter({ activity, selectedScopes }).map(
          (act) => act.userId
        )
      ),
    ];
  }
}

export default AppActivityHelper;
