import React from 'react';

import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import AppActivitySelectedList from '../AppActivitySlectedList/AppActivitySelectedList';

const useStyles = makeStyles(() => ({
  button: {
    marginRight: '10px',
  },
}));

const AppActivityHeaderButton = ({
  children,
  onClick,
  selectedItems,
  onFormatSelectedItemLabel,
}) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.button}>
      <Button fullWidth onClick={onClick} variant="contained">
        {children}
      </Button>

      <AppActivitySelectedList
        items={selectedItems}
        onFormatLabel={onFormatSelectedItemLabel}
      />
    </Grid>
  );
};

AppActivityHeaderButton.propTypes = {
  selectedItems: PropTypes.array,
  onClick: PropTypes.func.isRequired,
  onFormatSelectedItemLabel: PropTypes.array,
};

export default AppActivityHeaderButton;
