import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';

import Report from '../../Report';
import EditedAppHelper from '../../EditedAppHelper';
import { ACTIVITY_POPUP_MODES } from '../constants';
import AppActivityHeaderButton from './AppActivityHeaderButton';

import {
  useSelectedScopesLabel,
  useSelectedUserIdsLabel,
  useSelectedMediationRulesLabel,
} from './useAppActivityHeaderHooks';
import AppActivityHelper from '../AppActivityHelper';

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: '20px',
  },

  spacer: {
    flexGrow: 1,
  },
}));

const AppActivityHeader = ({
  onClick,
  onClickAdd,
  selectedScopes,
  selectedUserIds,
  selectedDateRange,
  selectedMediationRules,
}) => {
  const classes = useStyles();

  const scopeLabel = useSelectedScopesLabel(selectedScopes);
  const datesLabel = Report.renderSelectedDateRangeHint(selectedDateRange);
  const rulesLabel = useSelectedMediationRulesLabel(selectedMediationRules);
  const userIdLabel = useSelectedUserIdsLabel(selectedUserIds, selectedScopes);
  const hasSelectedMediation = AppActivityHelper.getHasSelectedMediation(selectedScopes);

  const handleClick = useCallback(
    (popupMode) => (e) => {
      const anchorEl = e.currentTarget;
      onClick(anchorEl, popupMode);
    },
    [onClick]
  );

  return (
    <Grid container direction="row" className={classes.container}>
      <AppActivityHeaderButton
        selectedItems={selectedScopes}
        onClick={handleClick(ACTIVITY_POPUP_MODES.SCOPE)}
      >
        {scopeLabel}
      </AppActivityHeaderButton>

      <AppActivityHeaderButton
        onClick={handleClick(ACTIVITY_POPUP_MODES.DATES)}
      >
        {datesLabel}
      </AppActivityHeaderButton>

      <AppActivityHeaderButton
        onClick={handleClick(ACTIVITY_POPUP_MODES.USER_ID)}
      >
        {userIdLabel}
      </AppActivityHeaderButton>

      {hasSelectedMediation && (
        <>
          <AppActivityHeaderButton
            onClick={handleClick(ACTIVITY_POPUP_MODES.RULES)}
          >
            {rulesLabel}
          </AppActivityHeaderButton>
        </>
      )}

      <Grid item className={classes.spacer} />

      <Grid item>
        {EditedAppHelper.canEdit() && (
          <Button onClick={onClickAdd} color="primary" variant="contained">
            <AddIcon />
            Add external activity
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

AppActivityHeader.propTypes = {
  selectedScopes: PropTypes.array,
  selectedUserIds: PropTypes.array,
  selectedDateRange: PropTypes.string,
  selectedMediationRules: PropTypes.array,

  onClick: PropTypes.func.isRequired,
  onClickAdd: PropTypes.func.isRequired,
};

export default AppActivityHeader;
