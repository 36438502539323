import DateRangeType from '../DateRangeType';

export const ACTIVITY_DATE_OPTIONS = [
  DateRangeType.TODAY,
  DateRangeType.YESTERDAY,
  DateRangeType.CURRENT_7_DAYS,
  DateRangeType.CURRENT_14_DAYS,
  DateRangeType.CURRENT_28_DAYS,
];

export const ACTIVITY_POPUP_MODES = {
  SCOPE: 'scope',
  DATES: 'dates',
  RULES: 'rules',
  USER_ID: 'userId',
};
