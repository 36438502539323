import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import AppActivitySelectedListItem from './AppActivitySlectedListItem';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '140px',
    overflowY: 'auto',
    marginTop: '6px',
    fontSize: '.75em',
    color: '#666',
  },

  spacer: {
    marginTop: '6px',
  },
}));

const AppActivitySelectedList = ({ items, onFormatLabel }) => {
  const classes = useStyles();
  const containerStyles = `thinscroll ${classes.container}`;

  const renderItems = () => {
    return items.map((item) => (
      <AppActivitySelectedListItem
        key={item}
        item={item}
        onFormatLabel={onFormatLabel}
      />
    ));
  };

  if (!items) {
    return null;
  }

  if (items.length === 0) {
    return <div className={classes.spacer}>none</div>;
  }

  return <div className={containerStyles}>{renderItems()}</div>;
};

AppActivitySelectedList.propTypes = {
  items: PropTypes.array,
  onFormatLabel: PropTypes.func,
};

export default AppActivitySelectedList;
