import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';

import AdNetworkType from './AdNetworkType';
import DateRangeSelector from './DateRangeSelector';
import DateRangeType from './DateRangeType';
import FiltersState from './FiltersState';
import Report from './Report';

import AdNetworkSelector from "./AdNetworkSelector";
import EditedAppHelper from "./EditedAppHelper";
import DateRange from "./DateRange";
import DataManager from "../store/DataManager";
import PamAppState from "../store/PamAppState";

const styles = (theme) => ({
  table: {
    minWidth: 700,
  },
  filterButton: {
    marginRight: '10px',
  },
});

const DATE_OPTIONS = [
  DateRangeType.YESTERDAY,
  DateRangeType.LAST_3_DAYS,
  DateRangeType.LAST_7_DAYS,
  DateRangeType.LAST_14_DAYS,
  DateRangeType.LAST_28_DAYS,
  DateRangeType.PREVIOUS_MONTH,
];

const DEFAULT_DATE_OPTION = DateRangeType.CURRENT_7_DAYS;

class AppAdNetworkFetcherRunner extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      anchorEl: null,
      popupMode: null,
      selectedDateRange:
        Report.getParamDateRangeFromUrl() ||
        FiltersState.getSelectedDates(DATE_OPTIONS, DEFAULT_DATE_OPTION),
      selectedAdNetwork: Report.getParamAdNetworkFromUrl() || FiltersState.getSelectedAdNetwork() || AdNetworkType.FACEBOOK,
    };
  }

  onDateRangeSelectionChange = (selected) => {
    FiltersState.setSelectedDates(selected);
    this.setState(
      {
        ...this.state,
        selectedDateRange: selected,
        anchorEl: null,
        popupMode: null,
      },
    );
  };

  onAdNetworkSelectionChange = (selected) => {
    FiltersState.setSelectedAdNetwork(selected);
    this.setState(
      {
        ...this.state,
        selectedAdNetwork: selected,
        anchorEl: null,
        popupMode: null,
      },
    );
  }

  handleFilterPopupClose = () => {
    this.setState(
      {...this.state, anchorEl: null, popupMode: null},
      this.handleUpdateUrl
    );
  };


  render() {
    return (
      <div className="content">
        {this.renderGeneralFilters()}
        {this.renderStartFetchingButton()}
      </div>
    );
  }

  renderGeneralFilters() {
    const {classes} = this.props;

    return (
      <div style={{marginBottom: '20px'}}>
        <Grid container direction="row">
          <Grid item className={classes.filterButton}>
            <Button
              variant="contained"
              fullWidth
              onClick={(e) =>
                this.setState({
                  ...this.state,
                  anchorEl: e.currentTarget,
                  popupMode: 'dates',
                })
              }
            >
              {Report.renderSelectedDateRangeHint(this.state.selectedDateRange)}
            </Button>
          </Grid>
          <Grid item className={classes.filterButton}>
            <Button
              variant="contained"
              fullWidth
              onClick={(e) =>
                this.setState({
                  ...this.state,
                  anchorEl: e.currentTarget,
                  popupMode: 'adNetwork',
                })
              }
            >
              {AdNetworkType.getName(this.state.selectedAdNetwork) || this.state.selectedAdNetwork}
            </Button>
          </Grid>
        </Grid>

        <Popover
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={!!this.state.anchorEl && this.state.popupMode === 'dates'}
          onClose={this.handleFilterPopupClose}
        >
          <DateRangeSelector
            options={DATE_OPTIONS}
            selected={this.state.selectedDateRange}
            onChange={this.onDateRangeSelectionChange}
          />
        </Popover>

        <Popover
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={!!this.state.anchorEl && this.state.popupMode === 'adNetwork'}
          onClose={this.handleFilterPopupClose}
        >
          <AdNetworkSelector
            selected={this.state.selectedAdNetwork}
            onChange={this.onAdNetworkSelectionChange}
          />
        </Popover>
      </div>
    );
  }

  renderStartFetchingButton() {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={this.handleStartFetching}
      >
        Fetch
      </Button>
    );
  }

  handleStartFetching = () => {
    let dateRange = DateRange.getDates(this.state.selectedDateRange);
    DataManager.startAppAdNetworkMetricsFetching(
      EditedAppHelper.getEditedApp().appData.app.appId,
      this.state.selectedAdNetwork,
      this.toDateString(dateRange.from),
      this.toDateString(dateRange.to),
      this.onFetchingStarted);
  };

  onFetchingStarted = (result) => {
    if (result.success) {
      PamAppState.addSnack({
        text: 'App Ad Network metrics fetching started',
        duration: 5000,
      });
    } else {
      PamAppState.setError('Failed to start App Ad Network metrics fetching started', result.error);
    }
  };

  toDateString = (date) => {
    const month = date.getMonth();
    const day = date.getDay();
    return date.getFullYear()
    + '-' + (month < 10 ? '0' + month : month)
    + '-' + (day < 10 ? '0' + day : day);
  }
}

export default withStyles(styles)(AppAdNetworkFetcherRunner);
