import React from 'react';

import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';

const AppActivityPopover = ({ open, onClose, anchorEl, children }) => {
  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {children}
    </Popover>
  );
};

AppActivityPopover.propTypes = {
  anchorEl: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AppActivityPopover;
