import React from 'react';
import PropTypes from 'prop-types';

import RuleSelector from '../../RuleSelector';
import Select from '../../common/Select/Select';
import AppActivityHelper from '../AppActivityHelper';
import DateRangeSelector from '../../DateRangeSelector';
import AppActionScopeSelector from '../../AppActionScopeSelector';
import { ACTIVITY_DATE_OPTIONS, ACTIVITY_POPUP_MODES } from '../constants';

const AppActivityPopoverContent = (props) => {
  const {
    onChange,
    popupMode,
    selectedScopes,
    selectedUserId,
    selectedDateRange,
    selectedMediationRules,
  } = props;

  switch (popupMode) {
    case ACTIVITY_POPUP_MODES.SCOPE: {
      return (
        <AppActionScopeSelector onChange={onChange} selected={selectedScopes} />
      );
    }

    case ACTIVITY_POPUP_MODES.DATES: {
      return (
        <DateRangeSelector
          onChange={onChange}
          selected={selectedDateRange}
          options={ACTIVITY_DATE_OPTIONS}
        />
      );
    }

    case ACTIVITY_POPUP_MODES.USER_ID: {
      return (
        <Select
          onChange={onChange}
          selectedOptions={selectedUserId}
          options={AppActivityHelper.getChangingUserIdsByScope(selectedScopes)}
        />
      );
    }

    case ACTIVITY_POPUP_MODES.RULES: {
      return (
        <RuleSelector onChange={onChange} selected={selectedMediationRules} />
      );
    }

    default: {
      return null;
    }
  }
};

AppActivityPopoverContent.propTypes = {
  selectedScopes: PropTypes.array,
  selectedUserId: PropTypes.array,
  selectedDateRange: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selectedMediationRules: PropTypes.array,
  popupMode: PropTypes.oneOf(Object.values(ACTIVITY_POPUP_MODES)),
};

export default AppActivityPopoverContent;
