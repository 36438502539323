export const SUPPORTED_PROPERTY_IDS = {
  country: 1,
  userBucket: 1,
  versionOs: 1,
  versionApp: 1,
  userAdvertisingId: 1,
  sdkVersion: 1,
  cpuArchitecture: 1,
  cpuCores: 1,
  ram: 1,
  installTimeMs: 1,
  random: 1,
  deviceModel: 1,
  deviceManufacturer: 1,
  gameUserId: 1,
  privacyConsent: 1,
  limitedAdTracking: 1,
  userAgent: 1,
};

export const SUPPORTED_PROPERTY_IDS_FOR_SDK_2_004_000 = {
  installTimeMs: 1,
  random: 1,
  deviceModel: 1,
  deviceManufacturer: 1,
};
