import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';

import AppSelector from './AppSelector';
import Country from './Country';
import CountrySelector from './CountrySelector';
import DataTable from './DataTable';
import DateRangeType from './DateRangeType';
import DateRangeSelector from './DateRangeSelector';
import EditReportDialog from './EditReportDialog';
import FiltersState from './FiltersState';
import Report from './Report';
import ReportType from './ReportType';

import DataManager from '../store/DataManager';
import PamAppState from '../store/PamAppState';
import StoreHelper from '../store/StoreHelper';
import DiscrepancyReportOptions from './DiscrepancyReportOptions';

const styles = (theme) => ({
  filtersRow: {
    marginBottom: '20px',
  },
  filterButton: {
    flexGrow: 1,
    marginRight: '10px',
    width: 0,
  },
});

const DATE_OPTIONS = [
  DateRangeType.TODAY,
  DateRangeType.YESTERDAY,
  DateRangeType.LAST_3_DAYS,
  DateRangeType.LAST_7_DAYS,
];

const DEFAULT_DATE_OPTION = DateRangeType.LAST_3_DAYS;

const DEFAULT_PERCENT_RANGE = {
  // Default discrepancy rank min diff percent
  min: 8,
  // Default discrepancy rank max diff percent
  max: 25,
  // Default discrepancy rank min diff value
  start: 1000,
};

class ScopeComparisonReport extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      anchorEl: null,
      popupMode: null,
      selectedApps:
        Report.getParamAppsFromUrl() || FiltersState.getSelectedApps(),
      selectedCountries:
        Report.getParamCountriesFromUrl() ||
        FiltersState.getSelectedCountries().countries,
      selectedCountriesExcluded:
        Report.getParamCountriesExcludedFromUrl() ||
        FiltersState.getSelectedCountries().isExclude,
      selectedDateRange:
        Report.getParamDateRangeFromUrl() ||
        FiltersState.getSelectedDates(DATE_OPTIONS, DEFAULT_DATE_OPTION),
      selectedMinPercentRange:
        Report.getParamDiscrepancyRankMinDiffPercentFromUrl() ||
        FiltersState.getSelectedPercentRange(DEFAULT_PERCENT_RANGE).min,
      selectedMaxPercentRange:
        Report.getParamDiscrepancyRankMaxDiffPercentFromUrl() ||
        FiltersState.getSelectedPercentRange(DEFAULT_PERCENT_RANGE).max,
      selectedPercentRangeStart:
        Report.getParamDiscrepancyRankMinValueFromUrl() ||
        FiltersState.getSelectedPercentRange(DEFAULT_PERCENT_RANGE).start,
      reportTitle: '',
      reportContent: [''],
      isSaveDialogOpen: false,
    };
  }

  componentDidMount() {
    this.loadReport();
  }

  loadReport() {
    const reportId = this.props.match.params.reportId;
    let params = null;
    if (reportId) {
      // set state, and then request the report
      let report = StoreHelper.getReportById(reportId);
      if (report) {
        params = report.reportParams;
      }
    }

    if (params) {
      this.setState(
        {
          ...this.state,
          selectedDateRange: params.date || DEFAULT_DATE_OPTION,
          selectedCountries:
            params.country && params.country.length > 0
              ? params.country.split(',')
              : null,
          selectedApps:
            params.appId && params.appId.length > 0
              ? params.appId.split(',')
              : null,
          selectedMinPercentRange:
            params.percentRange && params.percentRange.min
              ? params.percentRange.min
              : DEFAULT_PERCENT_RANGE.min,
          selectedMaxPercentRange:
            params.percentRange && params.percentRange.max
              ? params.percentRange.max
              : DEFAULT_PERCENT_RANGE.max,
          selectedPercentRangeStart:
            params.percentRange && params.percentRange.start
              ? params.percentRange.start
              : DEFAULT_PERCENT_RANGE.start,
        },
        this.requestReport
      );
    } else {
      // New report, use default state
      this.requestReport();
    }
  }

  handleReportContentChanged = (data) => {
    let content = data.content;
    let hasContent = !!content;
    if (!hasContent) {
      content = Report.EMPTY_CONTENT;
    }

    this.setState(
      { ...this.state, reportTitle: data.title, reportContent: content },
      this.handleUpdateUrl
    );
  };

  onAppSelectionChange = (selected) => {
    FiltersState.setSelectedApps(selected);
    this.setState({ ...this.state, selectedApps: selected });
  };

  onCountrySelectionChange = (selected, isExclude) => {
    if (selected && selected.length === Country.getAll().length) {
      selected = null;
    }
    FiltersState.setSelectedCountries(selected, isExclude);
    this.setState({
      ...this.state,
      selectedCountries: selected,
      selectedCountriesExcluded: isExclude,
    });
  };

  onDateRangeSelectionChange = (selected) => {
    FiltersState.setSelectedDates(selected);
    this.setState(
      {
        ...this.state,
        selectedDateRange: selected,
        anchorEl: null,
        popupMode: null,
      },
      this.requestReport
    );
  };

  onPercentRangeSelectionChange = (min, max, start) => {
    if (
      this.state.selectedMinPercentRange !== min ||
      this.state.selectedMaxPercentRange !== max ||
      this.state.selectedPercentRangeStart !== start
    ) {
      FiltersState.setSelectedPercentRange(min, max, start);
      this.setState(
        {
          ...this.state,
          selectedMinPercentRange: min,
          selectedMaxPercentRange: max,
          selectedPercentRangeStart: start,
          anchorEl: null,
          popupMode: null,
        },
        this.requestReport
      );
    } else {
      this.setState({ ...this.state, anchorEl: null, popupMode: null });
    }
  };

  handleFilterPopupClose = () => {
    this.requestReport();
    this.setState({ ...this.state, anchorEl: null, popupMode: null });
  };

  getReportParams = () => {
    let params = {};
    params['date'] = this.state.selectedDateRange;
    let paramCountries = Report.generateParamCountries(
      this.state.selectedCountries,
      this.state.selectedCountriesExcluded
    );
    if (paramCountries) {
      params['country'] = paramCountries;
    }
    if (this.state.selectedApps != null) {
      params['appId'] = this.state.selectedApps.join();
    }
    if (this.state.selectedMinPercentRange) {
      params['discrepancyRankMinDiffPercent'] =
        this.state.selectedMinPercentRange;
    }
    if (this.state.selectedMaxPercentRange) {
      params['discrepancyRankMaxDiffPercent'] =
        this.state.selectedMaxPercentRange;
    }
    if (this.state.selectedPercentRangeStart) {
      params['discrepancyRankMinValue'] = this.state.selectedPercentRangeStart;
    }
    return params;
  };

  requestReport = () => {
    DataManager.runReportScopeComparison(
      this.getReportParams(),
      this.handleDataManagerResponse
    );
  };

  handleDataManagerResponse = (response) => {
    if (response.success) {
      this.handleReportContentChanged(response.data);
    } else {
      PamAppState.setError('Report failed', response.error);
    }
  };

  handleUpdateUrl = () => {
    Report.updateReportUrlQueryParams(this.getReportParams(), {
      date: DEFAULT_DATE_OPTION,
    });
  };

  handleSend = (params) => {
    DataManager.sendReportScopeComparison(params, this.handleReportSent);
  };

  handleReportSent = (response) => {
    if (response.success) {
      PamAppState.addSnack({ text: response.data, duration: 5000 });
    }
  };

  handleSave = (params) => {
    this.setState({ ...this.state, isSaveDialogOpen: true });
  };

  handleRefresh = () => {
    let params = { ...this.getReportParams(), ignoreCache: true };
    DataManager.runReportScopeComparison(
      params,
      this.handleDataManagerResponse
    );
  };

  closeEditDialog = () => {
    this.setState({ ...this.state, isSaveDialogOpen: false });
  };

  onReportSaved = (report) => {
    PamAppState.addSnack({
      text: "Report saved into 'My reports'",
      duration: 5000,
    });
    this.props.history.push('/report/scope/' + report.reportId);
  };

  removeSelectedApp = (appId) => {
    let selected = this.state.selectedApps.filter((id) => id !== appId);
    if (selected.length === 0) {
      selected = null;
    }
    FiltersState.setSelectedApps(selected);
    this.setState(
      { ...this.state, selectedApps: selected },
      this.requestReport
    );
  };

  removeSelectedCountry = (countryId) => {
    let selected = this.state.selectedCountries.filter(
      (id) => id !== countryId
    );
    let isExclude = this.state.selectedCountriesExcluded;
    if (selected.length === 0) {
      selected = null;
      isExclude = false;
    }
    FiltersState.setSelectedCountries(selected, isExclude);
    this.setState(
      {
        ...this.state,
        selectedCountries: selected,
        selectedCountriesExcluded: isExclude,
      },
      this.requestReport
    );
  };

  render() {
    const { classes } = this.props;
    const hasReportContent =
      this.state.reportMeta && this.state.reportMeta.table;

    return (
      <div className="content">
        <Grid container direction="row" className={classes.filtersRow}>
          <Grid item className={classes.filterButton}>
            <Button
              variant="contained"
              fullWidth
              onClick={(e) =>
                this.setState({
                  ...this.state,
                  anchorEl: e.currentTarget,
                  popupMode: 'app',
                })
              }
            >
              {Report.renderSelectedAppsHint(this.state.selectedApps)}
            </Button>
            {Report.renderSelectedAppsFilter(
              this.state.selectedApps,
              this.removeSelectedApp
            )}
          </Grid>
          <Grid item className={classes.filterButton}>
            <Button
              variant="contained"
              fullWidth
              onClick={(e) =>
                this.setState({
                  ...this.state,
                  anchorEl: e.currentTarget,
                  popupMode: 'country',
                })
              }
            >
              {Report.renderSelectedCountriesHint(
                this.state.selectedCountries,
                this.state.selectedCountriesExcluded
              )}
            </Button>
            {Report.renderSelectedCountriesFilter(
              this.state.selectedCountries,
              this.state.selectedCountriesExcluded,
              this.removeSelectedCountry
            )}
          </Grid>
          <Grid item className={classes.filterButton}>
            <Button
              variant="contained"
              fullWidth
              onClick={(e) =>
                this.setState({
                  ...this.state,
                  anchorEl: e.currentTarget,
                  popupMode: 'dates',
                })
              }
            >
              {Report.renderSelectedDateRangeHint(this.state.selectedDateRange)}
            </Button>
          </Grid>
          <Grid item className={classes.filterButton}>
            <Button
              variant="contained"
              fullWidth
              onClick={(e) =>
                this.setState({
                  ...this.state,
                  anchorEl: e.currentTarget,
                  popupMode: 'percentrange',
                })
              }
            >
              Options
            </Button>
          </Grid>
        </Grid>
        <p>
          <b>{this.state.reportTitle}</b>
        </p>
        <DataTable
          data={(this.state.reportMeta || {}).table}
          emptyMessage={Report.EMPTY_CONTENT_MESSAGE}
        />
        {Report.renderActionButtons(
          hasReportContent,
          this.getReportParams(),
          null,
          this.handleSend,
          this.handleSave,
          this.handleRefresh,
          null
        )}

        <Popover
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={!!this.state.anchorEl && this.state.popupMode === 'app'}
          onClose={this.handleFilterPopupClose}
        >
          <AppSelector
            selected={this.state.selectedApps}
            onChange={this.onAppSelectionChange}
          />
        </Popover>
        <Popover
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={!!this.state.anchorEl && this.state.popupMode === 'country'}
          onClose={this.handleFilterPopupClose}
        >
          <CountrySelector
            selected={this.state.selectedCountries}
            showExclude={true}
            isExclude={this.state.selectedCountriesExcluded}
            onChange={this.onCountrySelectionChange}
          />
        </Popover>
        <Popover
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={!!this.state.anchorEl && this.state.popupMode === 'dates'}
          onClose={this.handleFilterPopupClose}
        >
          <DateRangeSelector
            options={DATE_OPTIONS}
            selected={this.state.selectedDateRange}
            onChange={this.onDateRangeSelectionChange}
          />
        </Popover>
        <Popover
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={
            !!this.state.anchorEl && this.state.popupMode === 'percentrange'
          }
          onClose={this.handleFilterPopupClose}
        >
          <DiscrepancyReportOptions
            min={this.state.selectedMinPercentRange}
            max={this.state.selectedMaxPercentRange}
            start={this.state.selectedPercentRangeStart}
            onChange={this.onPercentRangeSelectionChange}
          />
        </Popover>
        {this.state.isSaveDialogOpen ? (
          <EditReportDialog
            open={true}
            onClose={this.closeEditDialog}
            onSaved={this.onReportSaved}
            reportId={this.props.match.params.reportId}
            reportType={ReportType.SCOPE}
            reportParams={this.getReportParams()}
          />
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(ScopeComparisonReport));
