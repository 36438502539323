import { useMemo } from 'react';

import AppActionScope from '../../AppActionScope';
import EditedAppHelper from '../../EditedAppHelper';
import AppActivityHelper from '../AppActivityHelper';

export const useSelectedUserIdsLabel = (selectedUserIds, selectedScopes) => {
  return useMemo(() => {
    const userIdsByScope =
      AppActivityHelper.getChangingUserIdsByScope(selectedScopes);

    if (!selectedUserIds || selectedUserIds.length === userIdsByScope.length) {
      return 'Users (All)';
    }

    if (selectedUserIds.length === 1) {
      return selectedUserIds[0];
    }

    return `Users (${selectedUserIds.length})`;
  }, [selectedUserIds, selectedScopes]);
};

export const useSelectedScopesLabel = (selectedScopes) => {
  return useMemo(() => {
    const allScopes = AppActionScope.getAll();

    if (!selectedScopes || selectedScopes.length === allScopes.length) {
      return 'Scopes (All)';
    }

    return `Scopes (${selectedScopes.length})`;
  }, [selectedScopes]);
};

export const useSelectedMediationRulesLabel = (selectedMediationRules) => {
  return useMemo(() => {
    const allMediationRules = EditedAppHelper.getAllMediationRules();

    if (
      !selectedMediationRules ||
      selectedMediationRules.length === allMediationRules.length
    ) {
      return `Rules (All)`;
    }

    return `Rules (${selectedMediationRules.length})`;
  }, [selectedMediationRules]);
};
