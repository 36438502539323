import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';

import AdNetworkType from './AdNetworkType';
import AppIcon from './AppIcon';
import DateRangeSelector from './DateRangeSelector';
import DateRangeType from './DateRangeType';
import FiltersState from './FiltersState';
import Formatter from './Formatter';
import Report from './Report';
import OsType from './OsType';

import DataManager from '../store/DataManager';
import Store from '../store/Store';
import StoreHelper from '../store/StoreHelper';
import AppSelector from './AppSelector';

const styles = (theme) => ({
  table: {
    minWidth: 700,
  },
  filterButton: {
    marginRight: '10px',
  },
});

const DATE_OPTIONS = [
  DateRangeType.TODAY,
  DateRangeType.YESTERDAY,
  DateRangeType.LAST_7_DAYS,
  DateRangeType.CURRENT_7_DAYS,
  DateRangeType.LAST_14_DAYS,
  DateRangeType.LAST_28_DAYS,
  DateRangeType.LAST_60_DAYS,
  DateRangeType.THIS_MONTH,
  DateRangeType.PREVIOUS_MONTH,
];

const DEFAULT_DATE_OPTION = DateRangeType.CURRENT_7_DAYS;

class Alerts extends Component {
  constructor(props, context) {
    super(props, context);
    const isTechMode = props.mode === 'TECH';
    this.state = {
      isTechMode: isTechMode,
      showAppSelector: props.showAppSelector === undefined || props.showAppSelector,
      showHistory: props.showHistory === undefined || props.showHistory,
      anchorEl: null,
      popupMode: null,
      selectedApps:
        Report.getParamAppsFromUrl() || FiltersState.getSelectedApps(),
      selectedDateRange:
        Report.getParamDateRangeFromUrl() ||
        FiltersState.getSelectedDates(DATE_OPTIONS, DEFAULT_DATE_OPTION),
      refreshReportTimer: window.setInterval(
        this.requestInactiveAlerts,
        1000 * 60 * 5
      ),
      activeAlerts: StoreHelper.getActiveAlerts(isTechMode) || [],
      inactiveAlerts: [],
    };
  }

  componentDidMount() {
    Store.subscribe(Store.TOPIC_ACTIVE_ALERTS, this.handleActiveAlertsChanged);
    this.requestInactiveAlerts();
  }

  componentWillUnmount() {
    window.clearInterval(this.state.refreshReportTimer);
    Store.unsubscribe(this.handleActiveAlertsChanged);
  }

  handleActiveAlertsChanged = () => {
    this.setState({
      ...this.state,
      activeAlerts: StoreHelper.getActiveAlerts(),
    });
  };

  requestInactiveAlerts = () => {
    DataManager.loadInactiveAlerts(
      this.getAlertsHistoryParams(),
      this.handleInactiveAlertsResponse
    );
  };

  handleInactiveAlertsResponse = (response) => {
    if (response.success) {
      let allInactiveAlerts = response.data;
      let alertsByMode = allInactiveAlerts
        .filter(alert => alert.technical === this.state.isTechMode);
      this.setState(
        { ...this.state, inactiveAlerts: alertsByMode },
        this.handleUpdateUrl
      );
    }
  };

  handleUpdateUrl = () => {
    Report.updateReportUrlQueryParams(
      {
        date: this.state.selectedDateRange,
        appId: this.state.selectedApps && this.state.selectedApps.join(),
      },
      { date: DEFAULT_DATE_OPTION }
    );
  };

  getAlertsHistoryParams = () => {
    let params = {};
    if (this.state.selectedApps != null) {
      params['appId'] = this.state.selectedApps.join();
    }
    params['date'] = this.state.selectedDateRange;
    params['isActiveAlertStatus'] = false;
    return params;
  };

  onDateRangeSelectionChange = (selected) => {
    FiltersState.setSelectedDates(selected);
    this.setState(
      {
        ...this.state,
        selectedDateRange: selected,
        anchorEl: null,
        popupMode: null,
      },
      this.requestInactiveAlerts
    );
  };

  onAppSelectionChange = (selected) => {
    FiltersState.setSelectedApps(selected);
    this.setState(
      { ...this.state, selectedApps: selected },
      this.handleUpdateUrl
    );
  };

  removeSelectedApp = (appId) => {
    let selected = this.state.selectedApps.filter((id) => id !== appId);
    if (selected.length === 0) {
      selected = null;
    }
    FiltersState.setSelectedApps(selected);
    this.setState(
      { ...this.state, selectedApps: selected },
      this.handleUpdateUrl
    );
  };

  handleFilterPopupClose = () => {
    this.requestInactiveAlerts();
    this.setState(
      { ...this.state, anchorEl: null, popupMode: null },
      this.handleUpdateUrl
    );
  };

  handleAlertRowClick = (relatedUrl, isActiveAlert) => {
    if (isActiveAlert) {
      window.open(relatedUrl);
    }
  };

  render() {
    return (
      <div className="content">
        {this.state.showAppSelector ? this.renderGeneralFilters() : null}
        {this.renderActiveAlerts()}
        {this.state.showHistory ? Formatter.renderSectionHeader('Alerts history', true) : null}
        {this.state.showHistory ? this.renderInactiveFilters() : null}
        {this.state.showHistory ? this.renderAlertsTable(this.state.inactiveAlerts, false) : null}
      </div>
    );
  }

  renderActiveAlerts() {
    let filteredActiveAlerts = this.state.activeAlerts
      .slice()
      .filter(
        (alert) =>
          !alert.appId ||
          !this.state.selectedApps ||
          this.state.selectedApps.includes(alert.appId.toString())
      )
      .sort((a, b) => b.firstDetectionTimestamp - a.firstDetectionTimestamp);
    if (filteredActiveAlerts && filteredActiveAlerts.length > 0) {
      return (
        <div>
          {Formatter.renderSectionHeader('Active alerts', false)}
          {this.renderAlertsTable(filteredActiveAlerts, true)}
        </div>
      );
    } else {
      return (
        <Typography variant="h6">
          <Icon style={{ color: 'green' }}>done_outline</Icon> All clear, you
          have no active alerts
        </Typography>
      );
    }
  }

  renderGeneralFilters() {
    const { classes } = this.props;

    return (
      <div style={{ marginBottom: '20px' }}>
        <Grid container direction="row">
          <Grid item className={classes.filterButton}>
            <Button
              variant="contained"
              fullWidth
              onClick={(e) =>
                this.setState({
                  ...this.state,
                  anchorEl: e.currentTarget,
                  popupMode: 'app',
                })
              }
            >
              {Report.renderSelectedAppsHint(this.state.selectedApps)}
            </Button>
            {Report.renderSelectedAppsFilter(
              this.state.selectedApps,
              this.removeSelectedApp
            )}
          </Grid>
        </Grid>

        <Popover
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={!!this.state.anchorEl && this.state.popupMode === 'app'}
          onClose={this.handleFilterPopupClose}
        >
          <AppSelector
            selected={this.state.selectedApps}
            onChange={this.onAppSelectionChange}
          />
        </Popover>
      </div>
    );
  }

  renderInactiveFilters() {
    const { classes } = this.props;

    return (
      <div style={{ marginBottom: '20px' }}>
        <Grid container direction="row">
          <Grid item className={classes.filterButton}>
            <Button
              variant="contained"
              fullWidth
              onClick={(e) =>
                this.setState({
                  ...this.state,
                  anchorEl: e.currentTarget,
                  popupMode: 'dates',
                })
              }
            >
              {Report.renderSelectedDateRangeHint(this.state.selectedDateRange)}
            </Button>
          </Grid>
        </Grid>

        <Popover
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={!!this.state.anchorEl && this.state.popupMode === 'dates'}
          onClose={this.handleFilterPopupClose}
        >
          <DateRangeSelector
            options={DATE_OPTIONS}
            selected={this.state.selectedDateRange}
            onChange={this.onDateRangeSelectionChange}
          />
        </Popover>
      </div>
    );
  }

  renderAlertsTable(alerts, isActiveAlerts) {
    const { classes } = this.props;

    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="center">Alert Type</TableCell>
            <TableCell align="center">App</TableCell>
            <TableCell align="center">Network</TableCell>
            <TableCell align="center">Description</TableCell>
            <TableCell align="center">First detection</TableCell>
            <TableCell align="center">Last detection</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {alerts.map((alert) => (
            <TableRow
              hover
              key={alert.alertId}
              onClick={() =>
                this.handleAlertRowClick(alert.relatedUrl, isActiveAlerts)
              }
            >
              <TableCell component="th" scope="row" align="center">
                <div>{alert.type}</div>
                <div>{this.renderArrowIcon(alert.description)}</div>
              </TableCell>
              <TableCell align="left">
                <div style={{ display: 'flex' }}>
                  {this.renderAppIcon(alert.appId)}
                  {this.renderAppName(alert.appId)}
                </div>
              </TableCell>
              <TableCell align="center">
                {this.renderAdNetwork(alert.adNetworkType)}
              </TableCell>
              <TableCell
                align="left"
                dangerouslySetInnerHTML={{ __html: alert.description }}
              />
              <TableCell align="center">
                {Formatter.formatTimeAgo(alert.firstDetectionTimestamp)}
              </TableCell>
              <TableCell align="center">
                {Formatter.formatTimeAgo(alert.lastDetectionTimestamp)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  renderArrowIcon(description) {
    if (description.includes('drop')) {
      return <Icon style={{ color: 'red' }}>south</Icon>;
    }

    if (description.includes('rise')) {
      return <Icon style={{ color: 'green' }}>north</Icon>;
    }
  }

  renderAppIcon(appId) {
    if (!appId) {
      return null;
    }
    let app = StoreHelper.getApp(appId);
    return (
      <div style={{ marginRight: '10px' }}>
        <AppIcon app={app} />
      </div>
    );
  }

  renderAppName(appId) {
    if (appId) {
      let app = StoreHelper.getApp(appId);
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <span>{app.displayName}</span>
          <span style={{ color: '#888' }}>{OsType.getName(app.platform)}</span>
        </div>
      );
    }
  }

  renderAdNetwork(adNetworkType) {
    if (adNetworkType) {
      return AdNetworkType.renderIcon(adNetworkType);
    }
  }
}

export default withStyles(styles)(Alerts);
