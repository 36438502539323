import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import AdType from './AdType';
import Select from './common/Select/Select';
import EditedAppHelper from './EditedAppHelper';

const RuleSelector = ({ selected, onChange }) => {
  const { options, labels } = useMemo(() => {
    const labels = {};
    const options = {
      [AdType.getName(AdType.REWARDED_VIDEO)]: [],
      [AdType.getName(AdType.INTERSTITIAL)]: [],
    };

    const addOptions = (adType) => {
      const rules = EditedAppHelper.getMediationRules(adType);

      rules.forEach(({ name, ruleId }) => {
        // Identify the "default" rule for an ad type
        const rule = ruleId === 0 ? `${adType}_0` : ruleId;

        labels[rule] = name;
        options[AdType.getName(adType)].push(rule);
      });
    };

    addOptions(AdType.REWARDED_VIDEO);
    addOptions(AdType.INTERSTITIAL);

    return { labels, options };
  }, []);

  const handleFormatLabel = useCallback(
    (option) => {
      return labels[option];
    },
    [labels]
  );

  return (
    <Select
      options={options}
      onChange={onChange}
      selectedOptions={selected}
      onFormatLabel={handleFormatLabel}
    />
  );
};

RuleSelector.propTypes = {
  selected: PropTypes.array,
  adType: PropTypes.oneOf([AdType.REWARDED_VIDEO, AdType.INTERSTITIAL]),

  onChange: PropTypes.func.isRequired,
};

export default RuleSelector;
