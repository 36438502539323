import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    padding: '2px 6px',
  },
}));

const AppActivitySelectedListItem = ({ item, onFormatLabel }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {onFormatLabel ? onFormatLabel(item) : item}
    </div>
  );
};

AppActivitySelectedListItem.propTypes = {
  item: PropTypes.string,
  onFormatLabel: PropTypes.func,
};

export default AppActivitySelectedListItem;
