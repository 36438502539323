import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';

import AdNetworksSelector from './AdNetworksSelector';
import AdTypeSelector from './AdTypeSelector';
import AppSelector from './AppSelector';
import Country from './Country';
import CountrySelector from './CountrySelector';
import DateRangeType from './DateRangeType';
import DateRangeSelector from './DateRangeSelector';
import EditReportDialog from './EditReportDialog';
import FiltersState from './FiltersState';
import RenderedReport from './RenderedReport';
import Report from './Report';
import ReportType from './ReportType';

import DataManager from '../store/DataManager';
import PamAppState from '../store/PamAppState';
import StoreHelper from '../store/StoreHelper';

const styles = (theme) => ({
  filtersRow: {
    marginBottom: '20px',
  },
  filterButton: {
    flexGrow: 1,
    marginRight: '10px',
    width: 0,
  },
});

const DATE_OPTIONS = [
  DateRangeType.MONTH_TO_DATE,
  DateRangeType.PREVIOUS_MONTH,
];

class FinanceReport extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      anchorEl: null,
      popupMode: null,
      selectedApps: FiltersState.getSelectedApps(),
      selectedCountries: FiltersState.getSelectedCountries().countries,
      selectedCountriesExcluded: FiltersState.getSelectedCountries().isExclude,
      selectedAdNetworks: FiltersState.getSelectedAdNetworks(),
      selectedAdTypes: FiltersState.getSelectedAdTypes(),
      selectedDateRange: FiltersState.getSelectedDates(
        DATE_OPTIONS,
        DateRangeType.MONTH_TO_DATE
      ),
      reportTitle: '',
      reportContent: '',
      hasReportContent: false,
      reportMeta: null,
      isSaveDialogOpen: false,
    };
  }

  componentDidMount() {
    this.loadReport();
  }

  loadReport() {
    const reportId = this.props.match.params.reportId;
    let params = null;
    if (reportId) {
      // set state, and then request the report
      let report = StoreHelper.getReportById(reportId);
      if (report) {
        params = report.reportParams;
      }
    }

    if (params) {
      this.setState(
        {
          ...this.state,
          selectedDateRange: params.date || DateRangeType.MONTH_TO_DATE,
          selectedAdNetworks:
            params.adNetwork && params.adNetwork.length > 0
              ? params.adNetwork.split(',')
              : null,
          selectedAdTypes:
            params.adType && params.adType.length > 0
              ? params.adType.split(',')
              : null,
          selectedCountries: Report.parseParamCountries(params.country),
          selectedCountriesExcluded: Report.parseParamIsCountriesExcluded(
            params.country
          ),
          selectedApps:
            params.appId && params.appId.length > 0
              ? params.appId.split(',')
              : null,
        },
        this.requestReport
      );
    } else {
      // New report, use default state
      this.requestReport();
    }
  }

  handleReportContentChanged = (data) => {
    let content = data.content;
    let hasContent = content && content !== '';
    if (!hasContent) {
      content = Report.EMPTY_CONTENT;
    }
    this.setState({
      ...this.state,
      reportTitle: data.title,
      reportContent: content,
      reportMeta: data.meta,
      hasReportContent: hasContent,
    });
  };

  onAppSelectionChange = (selected) => {
    FiltersState.setSelectedApps(selected);
    this.setState(
      { ...this.state, selectedApps: selected },
      this.requestReport
    );
  };

  onCountrySelectionChange = (selected, isExclude) => {
    if (selected && selected.length === Country.getAll().length) {
      selected = null;
    }
    FiltersState.setSelectedCountries(selected, isExclude);
    this.setState(
      {
        ...this.state,
        selectedCountries: selected,
        selectedCountriesExcluded: isExclude,
      },
      this.requestReport
    );
  };

  onAdNetworkSelectionChange = (selected) => {
    FiltersState.setSelectedAdNetworks(selected);
    this.setState(
      { ...this.state, selectedAdNetworks: selected },
      this.requestReport
    );
  };

  onAdTypeSelectionChange = (selected) => {
    FiltersState.setSelectedAdTypes(selected);
    this.setState(
      { ...this.state, selectedAdTypes: selected },
      this.requestReport
    );
  };

  onDateRangeSelectionChange = (selected) => {
    FiltersState.setSelectedDates(selected);
    this.setState(
      {
        ...this.state,
        selectedDateRange: selected,
        anchorEl: null,
        popupMode: null,
      },
      this.requestReport
    );
  };

  handleFilterPopupClose = () => {
    this.setState({ ...this.state, anchorEl: null, popupMode: null });
  };

  getReportParams = () => {
    let params = {};
    params['date'] = this.state.selectedDateRange;
    let paramCountries = Report.generateParamCountries(
      this.state.selectedCountries,
      this.state.selectedCountriesExcluded
    );
    if (paramCountries != null) {
      params['country'] = paramCountries;
    }
    if (this.state.selectedApps != null) {
      params['appId'] = this.state.selectedApps.join();
    }
    if (this.state.selectedAdNetworks != null) {
      params['adNetwork'] = this.state.selectedAdNetworks.join();
    }
    if (this.state.selectedAdTypes != null) {
      params['adType'] = this.state.selectedAdTypes.join();
    }
    return params;
  };

  requestReport = () => {
    DataManager.runReportFinance(
      this.getReportParams(),
      this.handleDataManagerResponse
    );
  };

  handleDataManagerResponse = (response) => {
    if (response.success) {
      this.handleReportContentChanged(response.data);
    } else {
      PamAppState.setError('Report failed', response.error);
    }
  };

  handleUpdateUrl = () => {
    Report.updateReportUrlQueryParams(this.getReportParams());
  };

  handleSend = (params) => {
    DataManager.sendReportFinance(params, this.handleReportSent);
  };

  handleReportSent = (response) => {
    if (response.success) {
      PamAppState.addSnack({ text: response.data, duration: 5000 });
    }
  };

  handleSave = (params) => {
    this.setState({ ...this.state, isSaveDialogOpen: true });
  };

  handleRefresh = () => {
    let params = { ...this.getReportParams(), ignoreCache: true };
    DataManager.runReportFinance(params, this.handleDataManagerResponse);
  };

  closeEditDialog = () => {
    this.setState({ ...this.state, isSaveDialogOpen: false });
  };

  onReportSaved = (report) => {
    PamAppState.addSnack({
      text: "Report saved into 'My reports'",
      duration: 5000,
    });
    this.props.history.push('/report/finance/' + report.reportId);
  };

  removeSelectedApp = (appId) => {
    let selected = this.state.selectedApps.filter((id) => id !== appId);

    if (selected.length === 0) {
      selected = null;
    }

    FiltersState.setSelectedApps(selected);
    this.setState(
      { ...this.state, selectedApps: selected },
      this.requestReport
    );
  };

  removeSelectedCountry = (countryId) => {
    let selected = this.state.selectedCountries.filter(
      (id) => id !== countryId
    );
    let isExclude = this.state.selectedCountriesExcluded;

    if (selected.length === 0) {
      selected = null;
      isExclude = false;
    }

    FiltersState.setSelectedCountries(selected, isExclude);
    this.setState(
      {
        ...this.state,
        selectedCountries: selected,
        selectedCountriesExcluded: isExclude,
      },
      this.requestReport
    );
  };

  removeSelectedNetwork = (networkId) => {
    let selected = this.state.selectedAdNetworks.filter(
      (id) => id !== networkId
    );

    if (selected.length === 0) {
      selected = null;
    }

    FiltersState.setSelectedAdNetworks(selected);
    this.setState(
      { ...this.state, selectedAdNetworks: selected },
      this.requestReport
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="content">
        <Grid container direction="row" className={classes.filtersRow}>
          <Grid item className={classes.filterButton}>
            <Button
              variant="contained"
              fullWidth
              onClick={(e) =>
                this.setState({
                  ...this.state,
                  anchorEl: e.currentTarget,
                  popupMode: 'app',
                })
              }
            >
              {Report.renderSelectedAppsHint(this.state.selectedApps)}
            </Button>
            {Report.renderSelectedAppsFilter(
              this.state.selectedApps,
              this.removeSelectedApp
            )}
          </Grid>
          <Grid item className={classes.filterButton}>
            <Button
              variant="contained"
              fullWidth
              onClick={(e) =>
                this.setState({
                  ...this.state,
                  anchorEl: e.currentTarget,
                  popupMode: 'country',
                })
              }
            >
              {Report.renderSelectedCountriesHint(
                this.state.selectedCountries,
                this.state.selectedCountriesExcluded
              )}
            </Button>
            {Report.renderSelectedCountriesFilter(
              this.state.selectedCountries,
              this.state.selectedCountriesExcluded,
              this.removeSelectedCountry
            )}
          </Grid>
          <Grid item className={classes.filterButton}>
            <Button
              variant="contained"
              fullWidth
              onClick={(e) =>
                this.setState({
                  ...this.state,
                  anchorEl: e.currentTarget,
                  popupMode: 'adnetwork',
                })
              }
            >
              {Report.renderSelectedAdNetworksHint(
                this.state.selectedAdNetworks
              )}
            </Button>
            {Report.renderSelectedAdNetworksFilter(
              this.state.selectedAdNetworks,
              this.removeSelectedNetwork
            )}
          </Grid>
          <Grid item className={classes.filterButton}>
            <Button
              variant="contained"
              fullWidth
              onClick={(e) =>
                this.setState({
                  ...this.state,
                  anchorEl: e.currentTarget,
                  popupMode: 'adtype',
                })
              }
            >
              {Report.renderSelectedAdTypeHint(this.state.selectedAdTypes)}
            </Button>
          </Grid>
          <Grid item className={classes.filterButton}>
            <Button
              variant="contained"
              fullWidth
              onClick={(e) =>
                this.setState({
                  ...this.state,
                  anchorEl: e.currentTarget,
                  popupMode: 'dates',
                })
              }
            >
              {Report.renderSelectedDateRangeHint(this.state.selectedDateRange)}
            </Button>
          </Grid>
        </Grid>

        <p>
          <b>{this.state.reportTitle}</b>
        </p>

        {Report.renderActionButtons(
          this.state.hasReportContent,
          this.getReportParams(),
          null,
          this.handleSend,
          this.handleSave,
          this.handleRefresh
        )}

        <RenderedReport content={this.state.reportContent} />

        <Popover
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={!!this.state.anchorEl && this.state.popupMode === 'app'}
          onClose={this.handleFilterPopupClose}
        >
          <AppSelector
            selected={this.state.selectedApps}
            onChange={this.onAppSelectionChange}
          />
        </Popover>

        <Popover
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={!!this.state.anchorEl && this.state.popupMode === 'country'}
          onClose={this.handleFilterPopupClose}
        >
          <CountrySelector
            selected={this.state.selectedCountries}
            showExclude={true}
            isExclude={this.state.selectedCountriesExcluded}
            onChange={this.onCountrySelectionChange}
          />
        </Popover>

        <Popover
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={!!this.state.anchorEl && this.state.popupMode === 'adnetwork'}
          onClose={this.handleFilterPopupClose}
        >
          <AdNetworksSelector
            selected={this.state.selectedAdNetworks}
            onChange={this.onAdNetworkSelectionChange}
          />
        </Popover>

        <Popover
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={!!this.state.anchorEl && this.state.popupMode === 'adtype'}
          onClose={this.handleFilterPopupClose}
        >
          <AdTypeSelector
            selected={this.state.selectedAdTypes}
            onChange={this.onAdTypeSelectionChange}
          />
        </Popover>

        <Popover
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={!!this.state.anchorEl && this.state.popupMode === 'dates'}
          onClose={this.handleFilterPopupClose}
        >
          <DateRangeSelector
            options={DATE_OPTIONS}
            selected={this.state.selectedDateRange}
            onChange={this.onDateRangeSelectionChange}
          />
        </Popover>

        {this.state.isSaveDialogOpen ? (
          <EditReportDialog
            open={true}
            onClose={this.closeEditDialog}
            onSaved={this.onReportSaved}
            reportId={this.props.match.params.reportId}
            reportType={ReportType.FINANCE}
            reportParams={this.getReportParams()}
          />
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(FinanceReport));
